<template>
  <router-view/>
</template>

<style>
body {
  background-color: #111;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

.nowrap {
  white-space: nowrap;
}
</style>
