<template>
    <div class="schedule">
        <div class="container-fluid">
            <schedule-container-entry v-for="entry in schedule.entries" :key="entry.id" 
                :entry="entry"
                :active="entry.id === activeEntryId"
                :selected="entry.id === selectedEntryId"
                :playing-time="entry.id === activeEntryId ? activeEntryPlayingTime : null"
                :active-controls-state="activeControlsState"
                @on-click="onEntryClicked">
            </schedule-container-entry>
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, PropType, defineEmits } from 'vue';
import { ScheduleControlsStateType } from '@/types/ScheduleControlsStateType'
import ScheduleInterface from '@/types/Schedule.interface';
import ScheduleContainerEntry from './ScheduleContainerEntry.vue';
import ScheduleEntryInterface from '@/types/ScheduleEntry.interface';

const emits = defineEmits(['onPlay', 'onStop', 'onPause', 'onFwd', 'onEntryClick'])

const props = defineProps({
    schedule: {
        required: true,
        type: Object as PropType<ScheduleInterface>
    },
    activeEntryId: {
        required: false,
        type: Object as PropType<string | null>
    },
    selectedEntryId: {
        required: false,
        type: Object as PropType<string | null>
    },    
    activeControlsState: {
        required: false,
        type: Object as PropType<ScheduleControlsStateType | null>
    },
    activeEntryPlayingTime: {
        required: false,
        type: Number
    }
})

function onPlayClicked() {
    emits('onPlay')
}

function onStopClicked() {
    emits('onStop')
}

function onPauseClicked() {
    emits('onPause')
}

function onFwdClicked() {
    emits('onFwd')
}

function onEntryClicked(entry: ScheduleEntryInterface) {
    emits('onEntryClick', entry)
}
</script>

<style scoped>
.schedule {
  margin-top: 63px;
  padding-bottom: 135px;
}

h2 {
    font-size: 1.3em;
    color: rgb(232, 168, 255);
}
</style>