<template>
    <div class="row entry"        
        :class="{ 'active': active }" 
        @click="onEntryClicked">        
        <div class="col-1">
            <button :id="`schedule-entry-focus-${entry.id}`" v-focus style="border: none; background-color: inherit; color: inherit;">
                {{ entry.orderNo }}
            </button>
        </div>
        <div class="col-9">
            <i v-if="active && activeControlsState === 'playing'" class="bi bi-play-fill"></i>
            <i v-if="active && activeControlsState === 'paussed'" class="bi bi-pause-fill"></i>
            {{ entry.label }}
        </div>
        <div class="col-2 duration">
            {{ secondsToLabel(entry.durationInSeconds - (playingTime ?? 0)) }}
        </div>
        <div v-if="active || selected" class="col-11 offset-1 speak-text">
            {{ entry.speakTextAtStart }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType, defineProps, defineEmits } from 'vue';
import { secondsToLabel } from '@/services/formating.service'
import ScheduleEntryInterface from '@/types/ScheduleEntry.interface';
import { ScheduleControlsStateType } from '@/types/ScheduleControlsStateType';

const emits = defineEmits(['onClick'])

const props = defineProps({
    entry: {
        required: true,
        type: Object as PropType<ScheduleEntryInterface>
    },
    active: {
        required: false,
        type: Boolean
    },
    selected: {
        required: false,
        type: Boolean
    },    
    playingTime: {
        required: false,
        type: Object as PropType<number | null>
    },
    activeControlsState: {
        required: false,
        type: Object as PropType<ScheduleControlsStateType | null>
    }
})

function onEntryClicked() {
    emits('onClick', props.entry)
}
</script>

<style scoped>
    .entry {
        color: #bbb;
        background-color: #111;
        border-bottom: 3px solid #222;
        padding: 5px 5px 5px 5px;
        cursor: pointer;
    }

    .entry:hover {
        background-color: #222;
        border-bottom: 3px solid #333;
    }

    .entry.active {
        color: #d56bff;
        background-color: #b700ff21;
    }

    .entry .speak-text {
        color: #aaa;
        font-size: 0.85em;
    }

    .entry .duration {
        font-size: 0.9em;
    }
</style>