<template>
  <div class="top-bar">
    <select class="form-control" @change="onScheduleChanged">
      <option v-for="sch in schedules" :key="sch.label" 
          :value="sch.id">
        {{ sch?.label }} ({{ sch.author }}, {{secondsToLabel(totalEntrisLength(sch))}})
      </option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, PropType } from 'vue';
import { secondsToLabel } from '@/services/formating.service'
import ScheduleInterface from '@/types/Schedule.interface';

const emits = defineEmits(['onScheduleChange'])

const props = defineProps({
    schedule: {
        required: false,
        type: Object as PropType<ScheduleInterface | null>
    },
    schedules: {
        required: false,
        type: Object as PropType<ScheduleInterface[] | null>
    }
})

function totalEntrisLength(schedule: ScheduleInterface): number | undefined {
  const sum = schedule.entries.map(e => e.durationInSeconds)
    .reduce((partialSum, a) => partialSum + a, 0);
  return sum
}

function onScheduleChanged(e: any) {
  const scheduleId = e.target.value
  if (scheduleId)
    emits('onScheduleChange', scheduleId)
}
</script>

<style scoped>
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 63px;
  background-color: rgb(41, 0, 41);
  color: #aaa;
  padding: 10px 15px 10px 15px;
}

select, select:focus {
  background-color: rgb(61, 0, 61);
  border-color: #444;
  color: #ddd;
  font-size: 1.3em;
}
</style>