export function speak(text: string) {
    const url = `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=${googleApiKey}`
    const data = {
        'input': {
            'text': text
        },
        'voice': {
            'languageCode': 'cs-CZ',
            'name': 'cs-CZ-Standard-A',  // 'cs-CZ-Wavenet-A'
            'ssmlGender': 'FEMALE'
        },
        'audioConfig': {
            'audioEncoding': 'MP3'
        }
    };
    const otherparam = {
        headers: {
            "content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify(data),
        method: "POST"
    };
    fetch(url, otherparam)
        .then(
            data => {
                return data.json()
            }
        )
        .then(res => {
            play(res.audioContent);
        })
        .catch(error => {
            console.error(error);
        })
}

function play(input: string) {
    const base64MP3 = input;
    const audioSrc = `data:audio/mpeg;base64,${base64MP3}`;
    const audioElement = new Audio(audioSrc);
    audioElement.play();
}

const googleApiKey = 'AIzaSyDRpQ3Ju1nrPRMAyDBFbe6ngQswD0QacEE'