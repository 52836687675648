import ScheduleInterface from '../../src/types/Schedule.interface'

export const schedule_2: ScheduleInterface = {
    id: 'ASCD234SD-ASDJGGSDAS',
    label: 'Pránájáma test',
    author: 'Petr',
    entries: [
        {
            id: "1",
            orderNo: 1,
            durationInSeconds: 120,
            label: 'Příprava',
            speakTextAtStart: 'Připravíme se k cvičení.',
        },
        {
            id: "2",
            orderNo: 2,
            durationInSeconds: 300,
            label: 'Centrum pod nohama',
            speakTextAtStart: 'Prodýcháme centrum pod nohama. Snažíme se držet v pozorovateli.',
        },
        {
            id: "3",
            orderNo: 3,
            durationInSeconds: 60,
            label: 'Nohy od kotníků dolů',
            speakTextAtStart: 'Prodýcháme nohy od kotníků dolů.',
        },
    ]
}