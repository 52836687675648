import ScheduleInterface from '../../src/types/Schedule.interface'

export const schedule_1: ScheduleInterface = {
    id: 'ASCDXASD-ASDAXSDAS',
    label: 'Pránájáma standard',
    author: 'Petr',
    entries: [
        {
            id: "1",
            orderNo: 1,
            durationInSeconds: 60,
            label: 'Příprava',
            speakTextAtStart: 'Připravíme se k cvičení.',
        },
        {
            id: "2",
            orderNo: 2,
            durationInSeconds: 300,
            label: 'Centrum pod nohama',
            speakTextAtStart: 'Prodýcháme centrum pod nohama. Snažíme se držet v pozorovateli.',
        },
        {
            id: "3",
            orderNo: 3,
            durationInSeconds: 60,
            label: 'Nohy od kotníků dolů',
            speakTextAtStart: 'Prodýcháme nohy od kotníků dolů.',
        },
        {
            id: "4",
            orderNo: 4,
            durationInSeconds: 60,
            label: 'Kotníky',
            speakTextAtStart: 'Prodýcháme kotníky.',
        },
        {
            id: "5",
            orderNo: 5,
            durationInSeconds: 60,
            label: 'Lýtka a holeně',
            speakTextAtStart: 'Prodýcháme lýtka a holeně.',
        },
        {
            id: "6",
            orderNo: 6,
            durationInSeconds: 60,
            label: 'Kolena',
            speakTextAtStart: 'Prodýcháme kolena.',
        },
        {
            id: "7",
            orderNo: 7,
            durationInSeconds: 60,
            label: 'Stehna',
            speakTextAtStart: 'Prodýcháme stehna.',
        },
        {
            id: "8",
            orderNo: 8,
            durationInSeconds: 60,
            label: '1. čakra v rozkroku',
            speakTextAtStart: 'Prodýcháme první čakru v rozkroku.',
        },
        {
            id: "9",
            orderNo: 9,
            durationInSeconds: 60,
            label: 'Celé nohy',
            speakTextAtStart: 'Prodýcháme celé nohy - od centra pod nohama až do 1. čakry.',
        },
        {
            id: "10",
            orderNo: 10,
            durationInSeconds: 60,
            label: '2. čakra',
            speakTextAtStart: 'Prodýcháme druhou čakru - podbřišek a pohlavní orgány.',
        },
        {
            id: "11",
            orderNo: 11,
            durationInSeconds: 60,
            label: '3. čakra',
            speakTextAtStart: 'Prodýcháme třetí čakru - pupek a břicho.',
        },
        {
            id: "12",
            orderNo: 12,
            durationInSeconds: 60,
            label: 'Solar plexus',
            speakTextAtStart: 'Prodýcháme solar plexus.',
        },
        {
            id: "13",
            orderNo: 13,
            durationInSeconds: 60,
            label: '4. čakra',
            speakTextAtStart: 'Prodýcháme čtvrtou čakru - hrudník.',
        },
        {
            id: "14",
            orderNo: 14,
            durationInSeconds: 60,
            label: '5. čakra',
            speakTextAtStart: 'Prodýcháme pátou čakru - hrdelní důlek a celý krk.',
        },
        {
            id: "15",
            orderNo: 15,
            durationInSeconds: 300,
            label: 'Ruce',
            speakTextAtStart: 'Prodýcháme celé ruce - od dlaní až k ramenům a do páté čakry.',
        },
        {
            id: "16",
            orderNo: 16,
            durationInSeconds: 60,
            label: 'Pečetě',
            speakTextAtStart: 'Prodýcháme pečetě a dolní část hlavy.',
        },
        {
            id: "17",
            orderNo: 17,
            durationInSeconds: 60,
            label: '6. čakra',
            speakTextAtStart: 'Prodýcháme šestou čakru včetně prodloužené míchy.',
        },
        {
            id: "18",
            orderNo: 18,
            durationInSeconds: 60,
            label: 'Bindu',
            speakTextAtStart: 'Prodýcháme bindu - část mozku pod vrcholem hlavy, kde se vlasy točí do kolečka.',
        },
        {
            id: "19",
            orderNo: 19,
            durationInSeconds: 60,
            label: 'Celý mozek',
            speakTextAtStart: 'Prodýcháme celý mozek.',
        },
        {
            id: "20",
            orderNo: 20,
            durationInSeconds: 300,
            label: 'Centrum nad hlavou',
            speakTextAtStart: 'Prodýcháme centrum nad hlavou.',
        },
        {
            id: "21",
            orderNo: 21,
            durationInSeconds: 300,
            label: 'Pod nohama až nad hlavu a zpět',
            speakTextAtStart: 'Nádech pod nohama do centra nad hlavou a výdech zpět do centra pod nohama.',
        },
        {
            id: "22",
            orderNo: 22,
            durationInSeconds: 300,
            label: 'Z centra nad hlavou pod nohy a zpět',
            speakTextAtStart: 'Nádech v centru nad hlavou do centra pod nohama a výdech zpět nad hlavu.',
        },
        {
            id: "23",
            orderNo: 23,
            durationInSeconds: 300,
            label: 'Duchovní Srdce',
            speakTextAtStart: 'Prodýcháme duchovní Srdce.',
        },
        {
            id: "24",
            orderNo: 24,
            durationInSeconds: 300,
            label: 'Ze Srdce nad hlavu a zpět',
            speakTextAtStart: 'Nádech ze Srdce nad hlavu a výdech zpět do srdce.',
        },
        {
            id: "25",
            orderNo: 25,
            durationInSeconds: 300,
            label: 'Pod nohama nad hlavu a do srdce',
            speakTextAtStart: 'Nádech v centru pod nohama do centra nad hlavou a odtud výdech do Srdce.',
        },
        {
            id: "26",
            orderNo: 26,
            durationInSeconds: 10,
            label: 'Závěr',
            speakTextAtStart: 'Cvičení pomalu ukončíme.',
        },
    ]
}