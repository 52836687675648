<template>
  <top-bar :schedules="schedules" 
    :schedule="schedule"
    @on-schedule-change="onScheduleChanged">
  </top-bar>
  <schedule-container v-if="schedule" 
    :schedule="schedule" 
    :active-entry-id="activeScheduleEntry?.id"
    :selected-entry-id="null"
    :active-controls-state="actualScheduleControlsStateType"
    :active-entry-playing-time="activeEntryPlayingTimeInSec"
    @on-entry-click="scheduleEntryClicked">
  </schedule-container>
  <schedule-controls-container :active-state="actualScheduleControlsStateType" 
      :active-entry="activeScheduleEntry" 
      :playing-time="activeEntryPlayingTimeInSec"
      @on-play="schedulePlayClicked" 
      @on-stop="scheduleStop" 
      @on-pause="schedulePauseClicked" 
      @on-bwd="scheduleBwdClicked"       
      @on-fwd="scheduleFwdClicked">
  </schedule-controls-container>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { loadSchedulesAsync, loadScheduleAsync } from '@/services/schedule.service';
import { speak } from '@/services/textToSpeech.service';
import { ScheduleControlsStateType } from '@/types/ScheduleControlsStateType'
import ScheduleControlsContainer from '@/components/ScheduleControlsContainer.vue';
import ScheduleInterface from '@/types/Schedule.interface';
import ScheduleContainer from '@/components/ScheduleContainer.vue';
import ScheduleEntryInterface from '@/types/ScheduleEntry.interface';
import TopBar from '@/components/TopBar.vue';

const schedules = ref<ScheduleInterface[] | null>()
const schedule = ref<ScheduleInterface | null>()
const activeScheduleEntry = ref<ScheduleEntryInterface | null>()
const selectedScheduleEntry = ref<ScheduleEntryInterface | null>()
const actualScheduleControlsStateType = ref<ScheduleControlsStateType | null>()
const activeEntryPlayingTimeInSec = ref<number>(0)
let wakeLock = null

onMounted(async () => {
  console.clear()
  schedules.value = await loadSchedulesAsync()
  schedule.value = await loadScheduleAsync('VZXCX-XVX3R-CVE43')
  setInterval(doTimerAction, 1000)
  activateWakeLock()
})

async function activateWakeLock() {
  try {
    wakeLock = await navigator.wakeLock.request("screen");
  }
  catch {
    console.error('Some error occured while trying to execute request on WakeLock.')
  }
}

// async function releaseWakeLock() {
//   wakeLock.release().then(() => {
//     alert('released')
//     // TO DO
//     //wakeLock = null;
//   });
// }

function doTimerAction() {
  if (!activeScheduleEntry.value || actualScheduleControlsStateType.value !== 'playing') return
  if (activeEntryPlayingTimeInSec.value === 0) {
    runScheduleEntryStartSounds()
  }
  activeEntryPlayingTimeInSec.value++
  if (activeEntryPlayingTimeInSec.value > activeScheduleEntry.value.durationInSeconds) {
    setNextEntry()
  }
}

function runScheduleEntryStartSounds() {
  if (!activeScheduleEntry.value || actualScheduleControlsStateType.value !== 'playing') return
  playScheduleEntryStartSound()
  if (activeScheduleEntry.value.speakTextAtStart) {
    setTimeout(speak.bind(null, activeScheduleEntry.value.speakTextAtStart), 1100);
  }  
}

function playScheduleEntryStartSound() {
  var audio = new Audio('./sounds/ping.mp3');  
  audio.volume = 0.4
  audio.play();
}

function getDefaultScheduleEntry(): ScheduleEntryInterface | null {
  if (!schedule.value || !schedule.value.entries.length) return null;
  return schedule.value.entries[0]
}

function schedulePlayClicked() {
  if (!activeScheduleEntry.value) {
    const defaultEntry = getDefaultScheduleEntry()
    if (defaultEntry) activateScheduleEntry(defaultEntry)
  }
  if (!activeScheduleEntry.value) return
  focusToScheduleEntryById(activeScheduleEntry.value.id)
  actualScheduleControlsStateType.value = 'playing'
}

function scheduleStop() {
  actualScheduleControlsStateType.value = 'stopped'
  activeEntryPlayingTimeInSec.value = 0
}

function schedulePauseClicked() {
  if (!activeScheduleEntry.value) return
  if (actualScheduleControlsStateType.value === 'playing')
    actualScheduleControlsStateType.value = 'paussed'
  else if (actualScheduleControlsStateType.value === 'paussed')
    actualScheduleControlsStateType.value = 'playing'
}

function scheduleBwdClicked() {
  if (!activeScheduleEntry.value) return  
  if (activeScheduleEntry.value.orderNo <= 1) return
  setPreviousEntry()
}

function scheduleFwdClicked() {
  if (!activeScheduleEntry.value) return 
  setNextEntry()
}

function scheduleEntryClicked(entry: ScheduleEntryInterface) {
  setScheduleEntry(entry)
}

function setScheduleEntry(entry: ScheduleEntryInterface) {
  if (!schedule.value) return
  activateScheduleEntry(entry, false)
  activeEntryPlayingTimeInSec.value = 0
  actualScheduleControlsStateType.value = 'stopped'
}

function setPreviousEntry() {
  if (!schedule.value || !activeScheduleEntry.value) return
  if (activeScheduleEntry.value.orderNo <= 1) { 
    return
  }
  const entry = schedule.value.entries.find(e => e.orderNo === activeScheduleEntry.value!.orderNo - 1)
  if (entry) {
    activateScheduleEntry(entry)
    activeEntryPlayingTimeInSec.value = 0
    focusToScheduleEntryById(entry.id)
  }
}

function setNextEntry() {
  if (!schedule.value || !activeScheduleEntry.value) return
  const maxScheduleOrderNo = Math.max(...schedule.value.entries.map(e => e.orderNo))
  if (activeScheduleEntry.value.orderNo >= maxScheduleOrderNo) { 
    scheduleStop()
    return
  }
  const entry = schedule.value.entries.find(e => e.orderNo === activeScheduleEntry.value!.orderNo + 1)
  if (entry) {
    activateScheduleEntry(entry)
    activeEntryPlayingTimeInSec.value = 0
  }
}

function activateScheduleEntry(entry: ScheduleEntryInterface, focus = true) {
  activeScheduleEntry.value = entry
  if (focus) focusToScheduleEntryById(entry.id)
}

const focusToScheduleEntryById = (id: string) => {
  const element = document.getElementById(`schedule-entry-focus-${id}`)
  if (element) {
    const elementTop = element.getBoundingClientRect().top;
    window.scrollBy(0, elementTop - 200);
  }  
}

function onScheduleChanged(id: string) {
  const selectedSchedule = schedules.value?.find(s => s.id === id)
  if (!selectedSchedule) { 
    console.error(`Schedule with id ${id} not found`)
    alert('Objevila se chyba')
  }
  activeScheduleEntry.value = null
  actualScheduleControlsStateType.value = null
  activeEntryPlayingTimeInSec.value = 0
  schedule.value = selectedSchedule
}
</script>
