export function secondsToLabel(seconds: number | undefined): string {
    if (!seconds) return ''
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secondsLeft = seconds % 60

    const formattedHours = hours ? hours.toString().padStart(2, "0") : ''
    const formattedMinutes = minutes.toString().padStart(2, "0")
    const formattedSeconds = secondsLeft.toString().padStart(2, "0")
    console.log('formattedSeconds', formattedSeconds)

    return (formattedHours ? formattedHours + ':' : '') + (formattedMinutes + ':') + formattedSeconds
}