<template>
    <div class="schedule-controls-container">
        <div>
            <div class="active-track-label mb-2">
                <i v-if="activeState === 'playing'" class="bi bi-play-fill me-2"></i>
                <i v-if="activeState === 'paussed'" class="bi bi-pause-fill me-2"></i>
                    <label :class="{'invisible': !activeEntry }">
                        {{ activeEntry?.orderNo }}:&nbsp;
                        {{ activeEntry?.label ?? "..." }}
                </label>
            </div>
            <div class="progress mb-2">
                <div class="progress-bar" role="progressbar" :style="{ width: playingTimeInPct() }" :aria-valuenow="playingTimeInPct()" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
        <button class="btn btn-lg" 
            title="Dozadu" 
            :disabled="(activeEntry?.orderNo ?? 0) <= 1"
            @click="onBwdClicked">
            <i class="bi bi-skip-backward"></i>
        </button>
        <button class="btn btn-lg" 
            title="Zastavit" 
            :disabled="activeState !== 'playing' && activeState !== 'paussed'"
            @click="onStopClicked">
            <i class="bi bi-stop"></i>
        </button>        
        <button v-if="activeState !== 'playing'" 
            class="btn btn-lg" 
            :class="{'waitng': activeState === 'paussed'}"
            title="Spustit" 
            @click="onPlayClicked">
            <i class="bi bi-play"></i>
        </button>
        <button v-if="activeState === 'playing'" 
            class="btn btn-lg border" 
            :class="{'waiting': activeState === 'playing'}"
            title="Pauza" 
            @click="onPauseClicked">
            <i class="bi bi-pause"></i>
        </button>
        <button class="btn btn-lg" 
            title="Dopředu" 
            @click="onFwdClicked">
            <i class="bi bi-skip-forward"></i>
        </button>        
   </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, PropType } from 'vue';
import { ScheduleControlsStateType } from '@/types/ScheduleControlsStateType'
import ScheduleEntryInterface from '@/types/ScheduleEntry.interface';

const emits = defineEmits(['onPlay', 'onStop', 'onPause', 'onFwd', 'onBwd'])

const props = defineProps({
    activeState: {
        required: false,
        type: Object as PropType<ScheduleControlsStateType | null>
    },
    activeEntry: {
        required: false, 
        type: Object as PropType<ScheduleEntryInterface | null>
    },
    playingTime: {
        required: false,
        type: Object as PropType<number | null>
    }
})

function onPlayClicked() {
    emits('onPlay')
}

function onStopClicked() {
    emits('onStop')
}

function onPauseClicked() {
    emits('onPause')
}

function onFwdClicked() {
    emits('onFwd')
}

function onBwdClicked() {
    emits('onBwd')
}

function playingTimeInPct(): string {
    if (!props.activeEntry) return ""
    if (props.playingTime !== null && props.playingTime !== undefined) {
        const pct = Math.round(props.playingTime / props.activeEntry.durationInSeconds * 100)
        return `${pct}%`
    }
    return ''
}
</script>

<style scoped>
.schedule-controls-container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 135px;
    background-color: rgb(50, 0, 50);
    color: #eee;
    padding: 10px;
    text-align: center;
    border-top: 2px solid rgb(45, 0, 45);
}

.progress {
    background-color: #444;
    height: 7px;
}

.progress .progress-bar {
    background-color: rgb(195, 131, 195);
}

.btn {
    color: #eee;
    font-size: 2em;
    border: 1px solid rgb(114, 40, 114);
    background-color: rgb(76, 0, 76);
    margin-left: 10px;
}

.btn.waiting {
    border-color: #333;
}

</style>