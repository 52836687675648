import ScheduleInterface from "@/types/Schedule.interface";
import { schedule_1 } from '@/../public/schedules/schedule_1'
import { schedule_2 } from '@/../public/schedules/schedule_2'

export async function loadSchedulesAsync(): Promise<ScheduleInterface[]> {
    const schedules: ScheduleInterface[] = []
    schedules.push(schedule_1)
    schedules.push(schedule_2)
    return schedules
}

export async function loadScheduleAsync(id: string): Promise<ScheduleInterface> {
    return schedule_1
}